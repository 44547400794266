@import "_breakpoints";
@import "_colors";
@import "_mixins";

@import url('//fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,700;1,300&display=swap');

body, html{
  font-weight: 200;
  font-family: 'Poppins', sans-serif;
  font-size: 10px;
  background-color: $ex-light-grey;
}

.form-control {
    font-size: 1.4rem;
}

div {
    font-size: 1.5rem;
}

@include respond-to-min('large') {
    .container {
        max-width: 1400px;
    }
}

.clickable {
    &:hover, &:focus {
        cursor: pointer;
        text-decoration: underline;
    }
}

.home-page {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: #253340;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    a {
        color: white;
    }
}
.footer {
    position: absolute;
    bottom: 10px;
    left: 0;
    // margin: 0 auto;
    font-size: 1.2rem;
    color: white;
    width: 100vw;
    display: flex;
    justify-content: center;
    a {
        color: white;
    }
}

.infrastructure {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1 1 auto;
        padding: 1.5rem 0;
        border: thin solid #253340;
        margin: 0 1rem;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
        svg {
            color: #253340;
            font-size: 4rem;
            margin-bottom: .5rem;
        }
        .description {
            font-size: 1.5rem;
            text-transform: uppercase;
            font-weight: 700;
        }
    }
}

#firebaseui-auth-container {
    ul {
        list-style: none;
        padding: 0;
        .firebaseui-list-item {
            button {
                border: thin solid #CCC;
                display: flex;
                align-items: center;
                padding: 1rem;
                &:hover, &:focus {
                    cursor: pointer;
                    background-color: #EFEFEF !important;
                }
                .firebaseui-idp-text-short {
                    display: none;
                }
            }
            img {
                height: 25px;
                margin-right: 10px;
            }
        }
    }
}

.box {
    background-color: white;
    padding: 2rem 4rem;
    box-shadow: 0px 2px 15px #d7d7d7;
    h1 {
        font-weight: 400;
        font-size: 2.5rem;
    }
}

.btn {
    font-size: 1.5rem;
    line-height: 1;
    font-weight: 600;
    letter-spacing: .05em;
    border: 1px solid #253340;
    color: #253340;
    padding: 1.25rem 2.5rem;
    margin: 1em auto;
    display: inline-block;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    border-radius: 2px;
    text-decoration: none;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    &:hover, &:focus {
        background-color: #253340;
        color: white;
        text-decoration: none;
        cursor: pointer;
    }
    &.white-outline {
        background: transparent;
        color: white;
        border-color: white;
        &:hover, &:focus {
            background-color: white;
            color: #253340;
        }
    }

    &.white {
        background-color: white;
        border-color: white !important;
        color: #253340 !important;
        font-weight: 700;
        &:hover, &:focus {
            background-color: agua;
            border-color: agua !important;
        }
    }
    &.blue {
        background-color: #253340;
        border-color: #253340 !important;
        color: white !important;
        font-weight: 700;
        &:hover, &:focus {
            background-color: white;
            // border-color: agua !important;
            color: #253340 !important;
        }
    }
    &.grey {
        background-color: $light-grey;
        border-color: $light-grey !important;
        color: $dark-grey;
        font-weight: 700;
        &:hover {
            background-color: $light-grey;
            border-color: #253340 !important;
            color: #253340 !important;
        }
        &.active {
            color: white;
            background-color: #253340;
            border-color: #253340 !important;
            &:hover, &:focus {
                background-color: $light-grey;
            }

        }
    }
    &:disabled {
        background: $light-grey;
        border-color: $light-grey !important;
        color: $medium-grey !important;
        &:hover, &:focus {
            cursor: default;
            background: $light-grey;
            color: $medium-grey !important;
        }
    }
}

.create-btn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: #253340;
    margin: 1rem 4rem;
    a {
        color: #253340;
        text-decoration: none;
        font-weight: 700;
        text-transform: uppercase;
        svg {
            margin-right: .5rem;
        }
    }
}

.shsites-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: .75rem 1rem;
    background-color: #253340;
    color: #F2F2F2;
    margin: 2rem 0;
    .title {
        flex: 1 1 auto;
        font-weight: 400;
        font-size: 1.5rem;
        a {
            color: white;
            text-decoration: none;
            &:hover, &:focus {
                text-decoration: underline;
            }
        }
    }
    .links {
        margin-right: 2rem;
        span, a {
            margin-left: 1rem;
            color: white;
            text-decoration: none;
            svg {
                margin-right: .5rem;
            }
        }
    }
    .account {
        span,
        a {
            margin-left: 1rem;
            color: white;
        }
    }
}

pre {
    background: #EFEFEF;
    padding: 2rem;
    border-radius: 4px;
    margin-top: 2rem;
    border: thin solid #DEDEDE;
    box-shadow: 0px 2px 15px #d7d7d7;
    max-width: 100%;
    overflow-x: scroll;
    .clipboard-copy {
        display: flex;
        flex-direction: row;
        align-items: center;
        svg, i {
            margin-right: .5rem;
        }
    }
}

.converter-wrapper {
    display: flex;
    width: 100%;
    background: #FFF;
    flex-direction: row;
    .converter-content  {
        width: 100%;
        overflow: hidden;
    }
    .column {
        padding: 2rem;
    }
    .converter-nav {
        display: flex;
        flex-direction: column;
        flex-basis: 10%;
        min-width: 225px;
        background: #253340;
        color: white;
        .header {
            text-transform: uppercase;
            font-size: 1.3rem;
            font-weight: 500;
            margin-bottom: 1rem;
        }
        ul {
            list-style-type: none;
            margin-left: 0;
            padding-left: 0;
            li {
                margin-bottom: 1rem;
                a {
                    color: white;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .match {
        color: green;
        font-weight: 500;
    }
    .upgrade {
        color: orange;
        font-weight: 500;
    }
    .version {
        color: red;
        font-weight: 500;
    }
    .new {
        color: blue;
        font-weight: 500;
    }
    .action {
        font-size: 1.2rem;
        line-height: 1.75;
    }
}

.box-outline {
    background-color: white;
    border: 1px solid #CCC;
    padding: 2.5rem 2rem 1.5rem 2rem;
    width: 100%;
    position: relative;
    margin-bottom: 3rem;
    .box-title {
        position: absolute;
        top: -1rem;
        background-color: white;
        border: 1px solid #CCC;
        padding: .25rem 1rem;
        text-transform: uppercase;
        font-weight: 700;
        color: #797979;
        font-size: 1.2rem;
        &:hover {
            cursor: pointer;
            background: #EFEFEF;
        }
    }
}

.notice {
    background: #D7EBFF;
    padding: 1rem 1.5rem 1rem 1rem;
    border-radius: 4px;
    margin-bottom: 2rem;
    color: #253340;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .close-icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        i, svg {
            margin-left: .5rem;
        }
    }
}

// .form {
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     .display-input {
//         display: flex;
//         flex-direction: column;
//         flex-basis: 100%;
//         @include flexWidth(20, 25, 33.33, 50, 66.67, 75, 100);
//         padding-right: .5rem;
//         input, select, textarea {
//             font-size: 1.5rem;
//         }
//         &.join-right {
//             margin-right: 0px;
//             padding-right: 0px;
//             input {
//                 border-top-right-radius: 0px;
//                 border-bottom-right-radius: 0px;
//                 border-right-color: transparent;
//             }
//         }
//         &.join-left {
//             margin-left: 0px;
//             padding-left: 0px;
//             @include dropDown;
//             select {
//                 -webkit-border-top-left-radius: 0px;
//                 -webkit-border-bottom-left-radius: 0px;
//                 border-top-left-radius: 0px;
//                 border-bottom-left-radius: 0px;
//                 background-color: $light-grey;
//             }
//             input {
//                 border-top-left-radius: 0px;
//                 border-bottom-left-radius: 0px;
//             }
//         }
//         .form-group {
//             margin-bottom: 1.75rem;
//             position: relative;
//             label {
//                 font-size: 1.25rem;
//                 color: $dark-grey;
//                 // text-transform: uppercase;
//             }
//         }
//         .invalid-feedback {
//             text-align: left;
//             padding-left: .5rem;
//         }
//         .calculated,
//         .helper-text {
//             font-size: 1.2rem;
//             padding: .125rem .25rem .5rem .5rem;
//             position: absolute;
//             left: 0;
//             line-height: 1;
//             text-align: left;
//         }
//     }
//     &.horizontal {
//         .display-input {
//             flex-direction: row;
//             .form-group {
//                 width: 100%;
//                 display: flex;
//                 flex-direction: row;
//                 align-items: center;
//                 flex-wrap: wrap;
//                 label {
//                     flex-basis: 20%;
//                     font-size: 1.4rem;
//                     text-transform: none;
//                     font-weight: 400;
//                     line-height: 34px;
//                 }
//                 .form-control {
//                     border-color: $medium-grey;
//                 }
//                 input, select {
//                     flex-basis: 80%;
//                     font-size: 1.5rem;
//                     height: 32px;
//                 }
//                 .invalid-feedback, .helper-text {
//                     padding-top: .5rem;
//                     margin-left: 20%;
//                     width: 80%;
//                     font-size: 1.2rem;
//                     position: relative;
//                 }
//             }
//         }
//     }
//     &.inline {
//         width: 100%;
//         .display-input {
//             flex: 1 1 auto;
//         }
//         .btn {
//             &.icon-only {
//                 width: 4em;
//                 padding-left: 1rem;
//                 padding-right: 1rem;
//             }
//         }
//     }
//     &.was-validated {
//         .display-input {
//             input[type="date"] {
//                 margin-right: -1em;
//             }
//         }
//     }
// }
